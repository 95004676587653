<template>
  <v-container fluid>
    <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
      <v-card>
        <v-card-title class="pa-4 accent">
          <v-btn
            :to="{ path: '/notificacoes' }"
            small
            exact
            fab
            text
            class="mr-2"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-icon color="primary" left>mdi-feed</v-icon>
          Adicionar Notificação
          <v-spacer></v-spacer>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="">
          <h1 class="pa-4 text-h5 font-weight-bold">Dados Gerais</h1>

          <v-divider class="mb-6"></v-divider>
          <v-row>
            <v-col cols="12">
              <v-row dense class="px-4">
                <!-- Envio Imediato -->
                <v-col cols="12" md="4">
                  <v-text-field v-model="feed.title" :rules="formRules" label="Titulo">
                    
                  </v-text-field>
                </v-col>

                <!-- Mensagem -->
                <v-col cols="12">
                  <v-textarea
                    v-model="feed.mensagem"
                    label="Mensagem"
                    required
                    :rules="formRules"
                    dense
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-3">
          <v-btn text :to="{ path: '/notificacoes' }" exact color="primary">
            <v-icon class="mr-2" left>mdi-arrow-left</v-icon>
            Voltar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            @click="createFeed"
            :disabled="!validForm"
            color="xbColor"
            class="white--text"
          >
            <v-icon left>mdi-plus</v-icon>
            Adicionar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import { create as postFeed } from "@/api/admin/feed.js";
import { inputRequired } from "@/plugins/utils.js";
import { mapState } from "vuex";

export default {
  name: "NovoFeed",

  data() {
    return {
      feed: {
        show_feed: true,
        envio_imediato: false,
      },
      loading: false,
      validForm: true,
      formRules: [inputRequired],
    };
  },

  computed: {
    dropzoneOptions() {
      return {
        url: "/no-url",
        createfileThumbnails: false,
        acceptedFiles: ".png,.jpg,.jpeg",
        maxFilesize: 2,
        autoProcessQueue: false,
      };
    },

    ...mapState("Usuario", {
      user_id: (state) => state.usuario.id,
    }),

    notificar_types() {
      return ["PUSH", "FEED"];
    },
  },

  watch: {},

  methods: {
    createFeed() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        const feed = new FormData();

        let feed_2 = {};
        feed_2.title = this.feed.title;
        feed_2.mensagem = this.feed.mensagem;
        feed_2.user_id = this.user_id;

        for (let key in feed_2) {
          if (
            feed_2[key] !== null &&
            feed_2[key] !== undefined &&
            feed_2[key] !== ""
          ) {
            feed.append(key, feed_2[key]);
          }
        }

        postFeed(feed)
          .then((response) => {
            if (response.status === 201) {
              this.$toast.success("Criado com sucesso");
              this.loading = false;
              this.$router.push({ path: "/notificacoes" });
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },

  mounted() {},
};
</script>

<style lang="scss" scoped></style>
